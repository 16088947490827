.App {
  font-family: sans-serif;
  text-align: center;
}
body {
  background-color: #efeeed;
}

.main {
  /* width: 100px; */
  height: 50vh;
  text-align: center;
  /* align-items: center; */
  /* display: flex; */
  /* flex-direction: column; */
  /* margin-left: 600px; */
  /* justify-content: center; */
  font-size: 80px;
  /* position: relative; */
  margin:0 auto;
}
.main .title {
  
  display:inline-block;
  margin-top: 10%;
  font-family: "Ranchers", cursive;
  letter-spacing: 20px;
  text-decoration: none;
  color: black;
  /* margin:0 auto; */
}

.sub {
  display: flex;
  justify-content: space-around;
}
.link {
  text-decoration: none;
  color: gray;
}
.cargopic-div{
  display:flex;
  justify-content:space-evenly;
}

.cargopic {
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;
  /* width: 80%; */
  /* height:50vh; */
}
.form {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  text-align: center; */
}
.input {
  width: 30%;
  margin-top: 5px;
  align-items: center;
  margin-right: auto;
  margin-left: auto;/*MAKE CENTER*/
  height: 5vh;
}
button {
  width: 20%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 5px;
}
p {
  margin-top: 50px;
  text-align: justify;
  text-justify: inter-word;
}
.serviceDiv {
  display: flex;
  justify-content: space-between;
  padding: 2%;
  margin:auto;
}
.sdiv{
  padding:2%
}
.warehousePic{
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
  /* height:30vh; */
}

@media only screen and (max-width:479px){
  .main{
    height: 50%;
    width:50%;
    font-size: larger;
    margin-right: 40%;
    
  }
  .sub{
    flex-direction: column;
  }
  .warehousePic{
    height: 50%;
  }
  .shen{
    display:none;
  }
  .serviceDiv{
    flex-direction: column;
  }
}
